import React, { useState } from "react";
import styled from "styled-components";
import { AiIcon, LikeIcon, PauseIcon, PlayColorIcon, PlayIcon, TracksPause, TracksPlay, TracksPlayColor } from "icons";
import Skeleton from "react-loading-skeleton";

const SongsSearchResult = ({ userSongs, aiSongs }: any) => {
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [audioChanged, setAudioChanged] = useState<string>("pause");
  function toggleAudioPlayback(audioUrl: string) {
    if (currentAudio && currentAudio.src === audioUrl) {
      // If the same URL, toggle play/pause
      if (currentAudio.paused) {
        currentAudio.play();
        setAudioChanged("play");
      } else {
        currentAudio.pause();
        setAudioChanged("pause");
      }
    } else {
      // Otherwise, create a new Audio instance
      if (currentAudio) {
        setAudioChanged("pause");
        currentAudio.pause(); // Pause the previous audio
      }
      const newAudio = new Audio(audioUrl);
      setCurrentAudio(newAudio);
      newAudio.play();
      setAudioChanged("play");
    }
  }
  return (
    <SearchResultContentContainer>
      {userSongs &&
        userSongs.length > 0 &&
        userSongs.map((item: any) => (
          <SearchResultContent key={item.id}>
            <SearchResultContentLeft>
              <ImageFlex>
                <div
                  onClick={
                    item?.preview_url
                      ? () => toggleAudioPlayback(item?.preview_url)
                      : () => null
                  }
                >
                  {item?.preview_url && item?.preview_url !== "no preview" && (
                    <>
                      {currentAudio &&
                        currentAudio.src === item?.preview_url &&
                        !currentAudio.paused &&
                        audioChanged === "play" ? (
                        <TracksPause />
                      ) : (
                        <TracksPlay />
                      )}
                    </>
                  )}
                </div>
                <img
                  src={item?.album?.images[0]?.url}
                  alt="Search Result Poster"
                ></img>
              </ImageFlex>
              <div>
                <h6>{item?.name}</h6>
                <p>{item?.artists[0]?.name}</p>
              </div>
            </SearchResultContentLeft>
            <SearchResultContentRight>

              <LikeIcon />
            </SearchResultContentRight>
          </SearchResultContent>
        ))}
      {aiSongs.length < 1 && (
        <SearchResultContent>
          <SkeletonContainaer>
            <Skeleton style={{ width: "40px", height: "40px" }} />
            <div style={{ width: "100%" }}>
              <Skeleton style={{ width: "100%", height: "16px" }} />
              <Skeleton style={{ width: "100%", height: "16px" }} />
            </div>
          </SkeletonContainaer>
        </SearchResultContent>
      )}

      {aiSongs &&
        aiSongs?.data?.tracks.map((item: any) => (
          <SearchResultContent key={item.id}>
            <SearchResultContentLeft>
              <ImageFlex>
                <div
                  onClick={
                    item?.preview_url
                      ? () => toggleAudioPlayback(item?.preview_url)
                      : () => null
                  }
                >
                  {item?.preview_url && item?.preview_url !== "no preview" ? (
                    <>
                      {currentAudio &&
                        currentAudio.src === item?.preview_url &&
                        !currentAudio.paused &&
                        audioChanged === "play" ? (
                        <TracksPause />
                      ) : (
                        <TracksPlay />
                      )}
                    </>
                  ) : (
                    <TracksPlayColor color="#ccc" />
                  )}
                </div>
                <img src={item?.image?.url} alt="Search Result Poster"></img>
              </ImageFlex>
              <div>
                <h6>{item?.title}</h6>
                <p>{item?.artistName}</p>
              </div>
              <AiIcon />
            </SearchResultContentLeft>
            <SearchResultContentRight>
              <LikeIcon />
            </SearchResultContentRight>
          </SearchResultContent>
        ))}
    </SearchResultContentContainer>
  );
};

export default SongsSearchResult;

const SearchResultContentRight = styled.div`
  display: flex;
  gap: 24px; /* Increased gap to prevent overlap */
  align-items: center;
`;

const SkeletonContainaer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const ImageFlex = styled.section`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SearchResultContentLeft = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }

  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
  
  /* Adjust spacing between image and text */
  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const SearchResultContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const SearchResultContent = styled.div`
  padding: 12px 16px;
  border: solid 1px #f0f0f0;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  img {
    width: 34px;
    height: 34px;
  }
`;
