import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { Layout } from "components/Layout";
import "styles/spotify.scss";
import RoomOwnerScreen from "screens/RoomOwnerScreen";

import cookies from "js-cookie";
import {
  LOCALSTORAGE_VALUES,
  hasTokenExpired,
  refreshToken,
} from "services/spotify";
interface SpotifyProps {
  token: string;
}

export const Spotify: FC<SpotifyProps> = ({ token }) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const params = useParams();
  const { playlistId } = params;

  useEffect(() => {
    if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
      refreshToken();
      console.log("getting new spotify token....");
    }
  }, []);

    return (
      <Layout>
        <RoomOwnerScreen token={token} />
        {/* <NonRoomOwnerScreen token={token} data={data} /> */}
      </Layout>
    );
};
