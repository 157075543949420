import styled from "styled-components";
import { NextIcon, PauseIcon, PlayIcon } from "icons";
import { useSelector } from "react-redux";
import { cardDetails, globalPlayer } from "app/api/otherSlice";
import { useState, useEffect } from "react";
import { YouTubePlayer } from "components/YoutubePlayer";

const BottomPlayingCard = () => {
  const cardData = useSelector(cardDetails);
  const playerr = useSelector(globalPlayer);
  const [index, setIndex] = useState<number>(0);
  const [youtubePlayer, setYoutubePlayer] = useState(playerr);
  const [nextIndex, setNextIndex] = useState<number>(999);
  const [isPaused, setPaused] = useState<boolean>(false);
  const [track, setTrack] = useState<any>({});

  useEffect(() => {
    if (cardData.app === "spotify") {
      playerr?.addListener("player_state_changed", (state: any) => {
        if (!state) {
          return;
        }
        setIndex(state?.track_window.previous_tracks.length);
        setNextIndex(state?.track_window.next_tracks.length);
        setTrack(state.track_window.current_track);

        setPaused(state.paused);
      });
    }
    if (cardData.app === "apple") {
      
      const intervalId = setInterval(() => {
        if (playerr?.player?.queue?.position !== cardData.currentIndex) {
          setIndex(playerr?.player?.queue?.items?.length - 1);
          setNextIndex(playerr?.player?.queue?.position);
          setTrack(playerr?.player?.queue.items[playerr?.player?.queue?.position]);
        }
      }, 500); // Check every 500ms to keep UI in sync
  
      return () => clearInterval(intervalId); 
    }
  }, [playerr, cardData]);

  return (
    <>
      <BottomPlayingCardContainer>
        <BottomPlayingCardContainerLeft>
          <img
            src={
              track?.album?.images[0]?.url
                ? track?.album?.images[0]?.url
                : cardData?.app === "apple"
                  ? track?.attributes?.artwork?.url
                  : cardData?.image
            }
            alt="musicicon"
          />
          <div>
            <h2>{cardData?.roomName}</h2>
            <p>
              {track?.album ? (
                <p>
                  Now Playing: {track?.name} -{" "}
                  {track?.artists[0]?.name?.slice(0, 20 - 12) + "..."}
                </p>
              ) : track?.length > 0 ? (
                <p>
                  Now Playing: {track[index]?.attributes?.name} -{" "}
                  {track[index]?.attributes?.artistName?.slice(0, 20 - 3) +
                    "..."}
                </p>
              ) : cardData?.app === "apple" ? (
                <p>
                  Now Playing: {track?.attributes?.name} -{" "}
                  {track?.artistName?.slice(0, 20 - 3) + "..."}
                </p>
              ) : (
                <p>
                  Now Playing: {cardData?.songTitle} -{" "}
                  {cardData?.songAuthor?.slice(0, 20 - 3) + "..."}
                </p>
              )}
            </p>
          </div>
        </BottomPlayingCardContainerLeft>

        <BottomPlayingCardContainerRight>
          <div>
            {isPaused ? (
              <div
                onClick={
                  cardData.app === "spotify"
                    ? () => {
                        setPaused(false);
                        playerr.togglePlay();
                      }
                    : cardData.app === "apple"? async () => {
                        cardData?.play();
                        setPaused(false);
                      }: async()=>{

                        playerr.playVideo();
                        setPaused(false);
                      }
                }
              >
                <PlayIcon color="#009549B2" />
              </div>
            ) : (
              <div
                onClick={
                  cardData.app === "spotify"
                    ? () => {
                        setPaused(true);
                        playerr.togglePlay();
                      }
                    : cardData.app === "apple" ? async () => {
                        cardData?.pause();
                        setPaused(true);
                      }:async()=>{

                        playerr.pauseVideo();
                        setPaused(true);
                      }
                }
              >
                <PauseIcon color="#009549B2" />
              </div>
            )}
          </div>

          <div
            onClick={
              nextIndex > 0 && cardData.app === "spotify"
                ? () => {
                    setPaused(false);
                    playerr.nextTrack();
                  }
                : nextIndex < index && cardData.app === "apple"
                  ? async () => {
                      await playerr?.changeToMediaAtIndex(nextIndex + 1);
                      setTrack(playerr?.player?.queue.items[nextIndex + 1]);
                      setNextIndex((next) => next + 1);

                      setPaused(false);
                    }
                  : async() => {
                    playerr.nextVideo();
                  }
            }
          >
            {/* nextIndex */}
            <NextIcon
              color={
                (nextIndex === index && cardData.app === "apple") ||
                (nextIndex === 0 && cardData.app === "spotify") || (playerr?.getPlaylist()?.length-1 === cardData?.currentIndex && cardData.app === "youtube")
                  ? "#ffffff30"
                  : "#009549B2"
              }
            />
          </div>
        </BottomPlayingCardContainerRight>
        {cardData.app ==='youtube' &&
        <YouTubePlayer
        playlistId={cardData.playlistId}
        player={youtubePlayer}
        isPlaying={!isPaused}
        title={cardData.title}
        setPlayer={setYoutubePlayer}
        setIsplaying={setPaused}
        setSongDetails={setTrack}
      />
        }
      </BottomPlayingCardContainer>
    </>
  );
};

export default BottomPlayingCard;

const BottomPlayingCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #ffffff70;
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1000000;
`;

const BottomPlayingCardContainerLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 60px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 400;
  }
`;

const BottomPlayingCardContainerRight = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
