import { setCardDetails, setGlobalPlayer } from "app/api/otherSlice";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

interface YouTubePlayerProps {
  playlistId: string;
  player: any;
  isPlaying: boolean;
  setSongDetails: React.Dispatch<React.SetStateAction<any>>;
  setIsplaying: React.Dispatch<React.SetStateAction<boolean>>;
  setPlayer: React.Dispatch<React.SetStateAction<any>>;
  title: string;
}

// Extend the Window interface
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = ({
  playlistId,
  setPlayer,
  setSongDetails,
  title
}) => {
  
  const playerRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useDispatch();
  const updateCard = (cardDetails: any) => {
    dispatch(setCardDetails({ cardDetails }));
  };

  useEffect(() => {
    const loadYouTubeAPI = async () => {
      if (window.YT && window.YT.Player) {
        // If the API and the Player constructor are ready, create the player
        createPlayer();
      } else {
        // If not, wait for the API to be ready
        window.onYouTubeIframeAPIReady = createPlayer;
      }

      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      if (firstScriptTag && firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const createPlayer = () => {
      const playerr = new window.YT.Player(playerRef.current, {
        playerVars: { autoplay: 1, controls: 0 },
        events: {
          onReady: (event: any) => onPlayerReady(event, playerr),
          onStateChange: (event: any) => onPlayerStateChange(event, playerr),
          onError: onError,
        },
      });

      setPlayer(playerr);
    };

    loadYouTubeAPI();
  }, [playlistId]);

  const onPlayerReady = (event: any, player: any) => {
    player.loadPlaylist({ list: playlistId });
    player.playVideo();
    dispatch(setGlobalPlayer({ player }));
  };

  const onPlayerStateChange = (event: any, player: any) => {
    
    const song = player?.getVideoData()
    setSongDetails(song);

    const roomDetails = {
      roomID: playlistId,
      roomName: title,
      playlistId: playlistId,
      roomDescription: "Room description should be here",
      songAuthor: song?.author || "Unknown",
      songTitle: song?.title || "Unknown",
      comments: 0,
      roomMembers: 0,
      image: `https://img.youtube.com/vi/${song?.video_id}/0.jpg` ,
      player: null,
      IsRoomOwner: true,
      currentIndex: player?.getPlaylistIndex(),
      app: "youtube",
    };
    updateCard(roomDetails);

  };

  const onError = (event: any) => {
    console.log("Player error:", event.data);
  };

  const domain =
    window.location.hostname === "localhost"
      ? `http://localhost:3000`
      : `https://${window.location.hostname}`;
  return (
    <iframe
        id="youtube_player"
        width="100%"
        allow="autoplay"
        src={`https://www.youtube.com/embed?listType=playlist&list=${playlistId}&enablejsapi=1&origin=${domain}&autoplay=1`}
        style={{ display:"none" }}
        ref={playerRef}
      />
  );
};
