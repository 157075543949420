import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BackIcon } from "icons/BackIcon";
import styled from "styled-components";
import { ConnectAccountButton } from "components/ConnectAccountButton";
import {
    AppleMusicIcon,
    AudiomacIcon,
    SportifyIcon,
    YoutubeMusicIcon,
} from "icons/musicServicesIcon";
import { Layout } from "components/Layout";
import {
    useChangeMusicMutation,
    useConnectAppleMusicMutation,
    useDisconnectMusicMutation,
    useGetUserDetailsQuery,
    useRefreshMutation,
} from "features/auth/authApiSlice";
import { selectCurrentUser, setAuthUser, setCredentials } from "features/auth/authSlice";
import { MusicKitContext } from "components/MusicKitController";
import { LoadingScreen } from "components/common/LoadingScreen";
import BottomModalTwo from "components/Home/BottomModalTwo";
import { PlayaToast } from "utils";
import cookies from "js-cookie";
import { LOCALSTORAGE_KEYS } from "services/spotify";
import { IUser } from "@types";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import PlayBlackImage from "../assets/playa-black.png";

interface ConnectAccountsWrapperProps {
    primaryApp: string;
}

const NewUserConnectAccount = () => {
    const context = useContext(MusicKitContext);
    const dispatch = useDispatch();
    const [connectAppleMusic, { isLoading }] = useConnectAppleMusicMutation();
    const [disconnectMusic, { isLoading: disconnectMusicLoading }] =
        useDisconnectMusicMutation();
    const [
        changeMusic,
        { isLoading: changeMusicLoading, error: changeMusicError },
    ] = useChangeMusicMutation();
    const [refresh] = useRefreshMutation();
    // const user = useSelector(selectCurrentUser);
    const userr = useSelector(selectCurrentUser);
    const user =
        userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [priamryApp, setPrimaryApp] = useState<string>(
        user?.primaryStreamingService as string
    );
    const navigate = useNavigate();
    const [localUser, setLocalUser] = useState<IUser>();
    const [searchParams] = useSearchParams();
    const queryParam = searchParams.get('errorConnect');

    const { data, error, isLoading: getUserLoading } = useGetUserDetailsQuery('/auth/user-details');

    useEffect(() => {
        if (queryParam) {
            toast.error(queryParam);
        }
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/user-details`, {
                    headers: {
                        'Authorization': `Bearer ${cookies.get("playa_access_token")}`
                    }
                })
                setLocalUser(response.data);
                setPrimaryApp(response.data.primaryStreamingService)
            } catch (error) {
                console.log(error)
            }
        }
        fetchUser();
    }, [data, getUserLoading, changeMusicLoading, priamryApp, disconnectMusicLoading]);

    const authWithSpotify = () => {
        const token = cookies.get("playa_access_token"); // Retrieve the token from cookies
        if (!token) {
            console.error("Token not found! User might not be authenticated.");
            return;
        }

        window.open(
            `${process.env.REACT_APP_BACKEND_URL}/auth/new-user/login/spotify?state=${encodeURIComponent(token)}`,
            "_self"
        );
    };

    const authWithGoogle = () => {
        const token = cookies.get("playa_access_token"); // Retrieve the JWT token
        if (!token) {
            console.error("Token not found! User might not be authenticated.");
            return;
        }
        window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/new-user/google?state=${encodeURIComponent(token)}`, "_self");
    };

    const handleConnectWithApple = async () => {
        const instance = context?.instance;
        try {
            const result = await instance?.authorize();

            if (!result) {
                return;
            }
            const res: any = await connectAppleMusic({ accessToken: result });

            if (res?.data?.status === "success") {
                await refresh("");
                PlayaToast.success({ msg: res?.data?.message });
            } else {
                PlayaToast.error({ msg: res?.error?.data?.message });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeStreamingService = async () => {
        const res: any = await changeMusic({ service: priamryApp });
        if (res?.data?.status === "success") {
            await refresh("");
            PlayaToast.success({ msg: res?.data?.message });
        } else {
            PlayaToast.error({ msg: res?.error?.data?.message });
        }
        setOpenModal(false);
    };
    //
    const disconnectStreamingService = async () => {
        const res: any = await disconnectMusic({ service: priamryApp });
        if (res?.data?.message.includes("disconnected successfully")) {
            await refresh("");
            if (priamryApp === "spotify") {
                localStorage.setItem(LOCALSTORAGE_KEYS.accessToken, "");

                localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, "");
                localStorage.setItem(LOCALSTORAGE_KEYS.expireTime, "");

                localStorage.setItem(LOCALSTORAGE_KEYS.refreshToken, "");
            }

            PlayaToast.success({ msg: res?.data?.message });
        } else {
            PlayaToast.error({ msg: res?.error?.data?.message });
        }
        setOpenModal(false);
    };

    const handleRedirect = (app: string) => {
        if (app) {
            navigate("/home")
        } else {
            toast.error("Kindly connect a streaming platform!");
        }
    };

    const accountArr = [
        {
            app: "spotify",
            icon: <SportifyIcon />,
            callback: authWithSpotify,
            isConnected: localUser?.isSpotifyConnected,
            isPrimary: localUser?.primaryStreamingService === "spotify",
        },
        {
            app: "apple",
            icon: <AppleMusicIcon />,
            callback: handleConnectWithApple,
            isConnected: localUser?.isAppleConnected,
            isPrimary: localUser?.primaryStreamingService === "apple",
        },
        {
            app: "youtube",
            icon: <YoutubeMusicIcon />,
            callback: authWithGoogle,
            isConnected: localUser?.isGoogleConnected,
            isPrimary: localUser?.primaryStreamingService === "youtube",
        },
        // {
        //   app: "audiomac",
        //   icon: <AudiomacIcon />,
        //   callback: () => null,
        //   isConnected: false,
        //   isPrimary: localUser?.primaryStreamingService === "audiomac",
        // },
    ];
    return (
        <>
            {disconnectMusicLoading && <LoadingScreen />}
            <ConnectAccountsWrapper primaryApp={priamryApp}>
                <div className="connectedAccountHeader">
                    <img src={PlayBlackImage}></img>
                    <p>Connect your streaming service to host sessions and add songs to your playlist.</p>
                </div>
                <div className="connectedAccountsContainer">
                    {accountArr.map((acc) => {
                        return (
                            <div
                                key={acc.app}
                                onClick={() => acc.callback()}
                            >
                                <ConnectAccountButton
                                    description="Connect with"
                                    serviceIcon={acc.icon}
                                    isConnected={acc.isConnected}
                                    isPrimary={acc.isPrimary}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="action-btn" onClick={() => handleRedirect(priamryApp)}>
                    <p>Get started</p>
                </div>
            </ConnectAccountsWrapper>
            <Toaster />
        </>
    );
};

export default NewUserConnectAccount;

const ConnectAccountsWrapper = styled.section<ConnectAccountsWrapperProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100vh;
    margin-top: 100px;

  .connectedAccountHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .connectedAccountHeader p{
   text-align: center;
   margin-top: 12px;
  }


  .connectedAccountsContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center;  */
    gap: 10px;
    margin-top: 30px;
    width: 100%;
  }

  .headerDescription {
    margin: 0 auto;
  }

  .action-btn{
    width: 100%;
    background-color: ${(props) => (props.primaryApp ? '#008135' : '#D9D9D9')};
    margin-top: 100px;
    padding: 12px 16px;
    border-radius: 32px;

    p{
        color: #fff;
        text-align: center;
    }
  }
`;
