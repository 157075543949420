import { useEffect, useState } from "react";
import styled from "styled-components";
import { YouTubePlayer } from "./YoutubePlayer";
import {
  ShuffleIcon,
  PreviousIcon,
  PlayIcon,
  PauseIcon,
  NextIcon,
  PlayerIcon,
} from "icons";
import PlaceholderImg from "../assets/placeholder.png";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import { LoadingScreen } from "./common/LoadingScreen";
import { PlayaToast } from "utils";


export const WebplaybackYoutube = ({ isOwner, playlist, is_paused,title }) => {
 
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isPlaying, setIsplaying] = useState(true);
  const [player, setPlayer] = useState();
  const [songDetails, setSongDetails] = useState();
  const [isShuffled, setIsShuffled] = useState(null);
  const [originallPlaylist, setOriginallPlaylist] = useState([]);


  const shuffleArray = (array, currentSong) => {
    const arrayCopy = [...array];
    const currentIndex = arrayCopy.indexOf(currentSong); // Find the index of the song

    if (currentIndex === -1) {
      throw new Error("Song not found in the array");
    }

    // Remove the current song from the array
    const currentSongItem = arrayCopy.splice(currentIndex, 1);

    // Shuffle the remaining songs
    for (let i = arrayCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
    }

    // Return the current song as the first item, followed by the shuffled songs
    return currentSongItem.concat(arrayCopy);
  };
  

  function rearrangeArray(arr, str) {
    const index = arr.indexOf(str); // Find the index of the string in the array

    if (index === -1) {
      throw new Error("String not found in the array");
    }

    // Slice the array into two parts and rearrange
    const firstPart = arr.slice(index); // From the found index to the end
    const secondPart = arr.slice(0, index); // From the start to the found index
    return [...firstPart, ...secondPart];
  }

  useEffect(() => {
    if (
      !originallPlaylist ||
      (originallPlaylist && originallPlaylist?.length <= 0)
    ) {
      
      const originalPlaylist =
        player && player.getPlaylist && player.getPlaylist();
      setOriginallPlaylist(originalPlaylist);
    }
  });

  const onShuffle = (isShuffled) => {
    if (player && player.getPlaylistIndex && player.getPlaylist && player.getCurrentTime) {
      const originalPlaylist = player.getPlaylist();
      const videoData = player.getVideoData(); // Retrieve video data from the player
  
      // Save the current playback time
      const currentTime = player.getCurrentTime();
  
      // Ensure the original playlist is saved
      if (originalPlaylist && originalPlaylist.length > 0) {
        setOriginallPlaylist((prev) => prev.length > 0 ? prev : originalPlaylist);
      }
  
      if (isShuffled && originalPlaylist && originalPlaylist.length > 0) {
        // Shuffle the playlist and keep the current video first
        const shuffled = shuffleArray(originalPlaylist, videoData.video_id);
        // Load the shuffled playlist
        player.loadPlaylist(shuffled);
        // Restore the playback time after shuffling
        player.seekTo(currentTime, true);
      } else if (
        !isShuffled &&
        originallPlaylist &&
        originallPlaylist.length > 0
      ) {
        // Reorder the playlist to the original order
        const reordered = rearrangeArray(
          originalPlaylist,
          videoData.video_id
        );
        // Load the reordered playlist
        player.loadPlaylist(reordered);
        // Restore the playback time after reordering
        player.seekTo(currentTime, true);
      } else {
        setIsShuffled(false);
      }
    }
  };
  

  useEffect(() => {
    if (isShuffled && typeof isShuffled === "boolean") {
      PlayaToast.success({
        msg: "Shuffle On!",
      });
    } else if (!isShuffled && typeof isShuffled === "boolean") {
      PlayaToast.success({
        msg: "Shuffle Off!",
      });
    }

    onShuffle(isShuffled);
  }, [isShuffled]);
  const onPlay = () => {
    if (player && player.playVideo) {
      player.playVideo();
      setIsplaying(true);
    }
  };

  const onPause = () => {
    if (player && player.pauseVideo) {
      player.pauseVideo();
      setIsplaying(false);
    }
  };

  const onNext = () => {
    if (player && player.nextVideo) {
      player.nextVideo();
    }
  };

  const onPrev = () => {
    if (player && player.previousVideo) {
      player.previousVideo();
    }
  };

  return (
    <TrackContainer>
      <YouTubePlayer
        playlistId={playlist}
        player={player}
        isPlaying={isPlaying}
        title={title}
        setPlayer={setPlayer}
        setIsplaying={setIsplaying}
        setSongDetails={setSongDetails}
      />
      {songDetails ? (
        <>
          <TrackCoverContainer>
            {playlist ? (
              <img
                src={
                  songDetails?.video_id
                    ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                    : ""
                }
                alt="playlist cover"
                width={320}
                height={180}
              />
            ) : (
              <img src={PlaceholderImg} alt="playlist cover" />
            )}
          </TrackCoverContainer>
          {isOwner && (
            <PlayerControlsContainer>
              {/* <div onClick={onShuffle}> */}
              <div onClick={() => setIsShuffled(!isShuffled)}>
                <ShuffleIcon color={isShuffled ? "#008135" : null} />
              </div>
              <div onClick={player?.getPlaylistIndex()>0 ? onPrev:()=>null}>
                <PreviousIcon color={player?.getPlaylistIndex()>0 ? null : '#cccccc'}/>
              </div>
              <div>
                {!isPlaying ? (
                  <div id="play-button" onClick={onPlay}>
                    <PlayIcon />
                  </div>
                ) : (
                  <div id="pause-button" onClick={onPause}>
                    <PauseIcon />
                  </div>
                )}
              </div>

              <div onClick={originallPlaylist?.length-1 === player.getPlaylistIndex() ? ()=>null:onNext}>
                <NextIcon color={originallPlaylist?.length-1 === player.getPlaylistIndex() ? '#cccccc' : null}/>
              </div>
              <div onClick={() => setShowPlaylist(!showPlaylist)}>
                <PlayerIcon />
              </div>
            </PlayerControlsContainer>
          )}
          <TrackDescription>
            <h5>{songDetails?.title}</h5>
            <Artist>
              <h6>{songDetails?.author}</h6>
            </Artist>
          </TrackDescription>
          {showPlaylist && (
            <div
              style={{
                position: "absolute",
                zIndex: 100,
                top: 0,
                left: 0,
                backgroundColor: "#ffffff",
                width: "100%",
              }}
            >
              <ViewPlayListScreen
                current_track={{
                  album: {
                    images: [
                      {
                        url: songDetails?.video_id
                          ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                          : "",
                      },
                    ],
                  },
                }}
                setShowPlaylist={setShowPlaylist}
              />
            </div>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </TrackContainer>
  );
};

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TrackCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
  }
`;

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
