import styled from "styled-components";
import axios from "axios";
import PlaceholderImg from "../assets/placeholder.png";
import { useNavigate } from "react-router-dom";
import { useGetRoomQuery } from "features/auth/authApiSlice";
import { useParams } from "react-router-dom";
import {
  LikeIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PlayerIcon,
  PreviousIcon,
  ShuffleIcon,
} from "icons";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import { useEffect, useState } from "react";
import { addTrackToLibrary, deleteTrackToLibrary } from "services/spotify";
import { PlayaToast } from "utils";

export const Webplayback = ({
  current_track,
  player,
  is_paused,
  token,
  playerState,
  isOwner,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { roomId } = params;
  const { data } = useGetRoomQuery(roomId);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isShuffle, setIsShuffle] = useState(null);
  
  useEffect(() => {
    const likedTracks = JSON.parse(localStorage.getItem("likedTracks")) || [];
    if (likedTracks.includes(current_track?.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    

  }, [current_track]);

  const toggleLike = (trackId) => {
    let likedTracks = JSON.parse(localStorage.getItem("likedTracks")) || [];

    if (isLiked) {
      // Unlike the track
      deleteTrackToLibrary(trackId);
      likedTracks = likedTracks.filter((id) => id !== trackId);
      setIsLiked(false);
    } else {
      // Like the track
      addTrackToLibrary(trackId);
      likedTracks.push(trackId);
      setIsLiked(true);
    }

    // Update localStorage
    localStorage.setItem("likedTracks", JSON.stringify(likedTracks));
  };

  const shufflePlayBack = async () => {
    try {
      // Enable or disable shuffle
      const response = await axios.put(
        `https://api.spotify.com/v1/me/player/shuffle?state=${isShuffle}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        `Shuffle ${isShuffle ? "enabled" : "disabled"}:`,
        response.data
      );
    } catch (error) {
      if (error.response) {
        console.error("Spotify API Error:", error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  useEffect(() => {
    isShuffle && typeof isShuffle ==='boolean'
      ? PlayaToast.success({
          msg: "Shuffle On!",
        })
      : PlayaToast.success({
          msg: "Shuffle Off!",
        });
    shufflePlayBack(isShuffle);
  }, [isShuffle]);

  return (
    <TrackContainer>
      <TrackCoverContainer>
        {current_track && current_track?.album ? (
          <img
            src={current_track?.album?.images[0]?.url}
            alt="playlist cover"
          />
        ) : (
          <img src={PlaceholderImg} alt="playlist cover" />
        )}
      </TrackCoverContainer>
      {isOwner && (
        <PlayerControlsContainer>
          <div onClick={() => setIsShuffle(!isShuffle)}>
            <ShuffleIcon color={isShuffle ? "#008135" : null} />
          </div>
          <div
            onClick={() => {
              player.previousTrack();
            }}
          >
            <PreviousIcon />
          </div>
          <div>
            {is_paused ? (
              <div
                onClick={() => {
                  player.togglePlay();
                }}
              >
                <PlayIcon />
              </div>
            ) : (
              <div
                onClick={() => {
                  player.togglePlay();
                }}
              >
                <PauseIcon />
              </div>
            )}
          </div>

          <div
            onClick={() => {
              player.nextTrack();
            }}
          >
            <NextIcon />
          </div>

          {/* <div onClick={() => navigate(`/viewplaylist/${data?.room?._id}`)}> */}
          <div onClick={() => setShowPlaylist(!showPlaylist)}>
            <PlayerIcon />
          </div>
        </PlayerControlsContainer>
      )}

      <TrackDescription>
        <h5>{current_track?.name}</h5>
        <Artist>
          <h6>{current_track?.artists[0]?.name}</h6>
          <LikeButton
            onClick={() => toggleLike(current_track?.id)}
            isLiked={isLiked}
          >
            {isLiked ? <LikeIcon /> : <LikeIcon />}
          </LikeButton>
        </Artist>
      </TrackDescription>
      {showPlaylist && (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            backgroundColor: "#ffffff",
            width: "100%",
          }}
        >
          <ViewPlayListScreen
            current_track={current_track}
            setShowPlaylist={setShowPlaylist}
          />
        </div>
      )}
    </TrackContainer>
  );
};

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TrackCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LikeButton = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease;

  &:active {
    transform: scale(1.2);
  }

  svg {
    fill: ${({ isLiked }) => (isLiked ? "#008135" : "tansparent")};
    stroke: #008135;
    transition: fill 0.2s ease;
    width: 30px;
    height: 34px;
  }
`;
